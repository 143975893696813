import React from "react";
import {
  Container,
  Box,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { pink, purple, cyan } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: pink[500],
    },
    secondary: {
      main: cyan[500],
    },
    background: {
      default: "#1e1e2f",
      paper: "#2a2a3d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b5b5b5",
    },
  },
  typography: {
    fontFamily: "'Press Start 2P', sans-serif",
    h3: {
      color: cyan[500],
    },
    h4: {
      color: pink[300],
    },
    h5: {
      color: purple[200],
    },
    body1: {
      color: "#b5b5b5",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="root" sx={{ bgcolor: "background.default" }}>
        
        {/* Landing Section */}
        <Box paddingTop={4} mb={4} textAlign="center">
          <Typography variant="h3" component="h1" gutterBottom>
            Wylie Coleman: She done did code stuff
          </Typography>
          <Link
            href="https://www.linkedin.com/in/wyliecoleman"
            target="_blank"
            rel="noopener"
            color="primary"
            sx={{ textDecoration: "underline", fontSize: "1.1em" }}
          >
            LinkedIn (It's like, my virtual home)
          </Link>
        </Box>

        {/* Skills Section */}
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            Skills That Pay The Bills
          </Typography>
          <Typography variant="body1">
            <strong>Skillfully Skilled At:</strong> Python, SQL, Java, C#, Spark, Databricks, Cloud Development
          </Typography>
          <Typography variant="body1">
            <strong>Software Engineering:</strong> Microservices, Architectural Design, Test Driven Development, Algorithm Analysis
          </Typography>
        </Box>

        {/* Experience Section */}
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            Where I’ve Been
          </Typography>

          <Box mb={2}>
            <Typography variant="h5">Software Engineer II, Markel Insurance</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              March 2022 - Present
            </Typography>
            <List>
              <ListItem><ListItemText primary="🚀 Led creation and onboarding of new teams." /></ListItem>
              <ListItem><ListItemText primary="✨ Set up standards to keep codebase spotless." /></ListItem>
              <ListItem><ListItemText primary="🔧 Built tools for app configurations." /></ListItem>
              <ListItem><ListItemText primary="📈 Improved CI/CD strategies to streamline workflows." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Software Engineer I, Markel Insurance</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              June 2021 - March 2022
            </Typography>
            <List>
              <ListItem><ListItemText primary="💾 Revamped core legacy ETL processes." /></ListItem>
              <ListItem><ListItemText primary="💼 Trained new team members on the company's toolset." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Software Engineer, Book Bundler</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              February 2021 - October 2021
            </Typography>
            <List>
              <ListItem><ListItemText primary="📊 Migrated to a database-driven inventory system." /></ListItem>
              <ListItem><ListItemText primary="🔄 Built a multi-channel sales management platform." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Software Engineer (Contractor), Markel Insurance</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              January 2021 - June 2021
            </Typography>
            <List>
              <ListItem><ListItemText primary="🔍 Investigated security flaws and provided fixes." /></ListItem>
              <ListItem><ListItemText primary="☁️ Emulated on-premise systems in cloud environments." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Interim Software Engineer, Markel Insurance</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              August 2020 - January 2021
            </Typography>
            <List>
              <ListItem><ListItemText primary="🤖 Built a man-in-the-middle pattern recognition tool." /></ListItem>
              <ListItem><ListItemText primary="💸 Developed secure API for real-time financial snapshots." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Software Engineering Intern, Markel Insurance</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              May 2020 - August 2020
            </Typography>
            <List>
              <ListItem><ListItemText primary="🌐 Redesigned applications to enable cloud migration." /></ListItem>
              <ListItem><ListItemText primary="📊 Created reports to assist in system decommissioning." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Lead Embedded Software Developer, JBR Technologies</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              April 2014 - June 2015
            </Typography>
            <List>
              <ListItem><ListItemText primary="🔊 Developed tools for audio event analysis." /></ListItem>
              <ListItem><ListItemText primary="🔌 Worked on hardware control interface design." /></ListItem>
            </List>
          </Box>
        </Box>

        {/* Projects Section */}
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            Project Highlights
          </Typography>

          <Box mb={2}>
            <Typography variant="h5">Virtual Anderson Art Gallery</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              April 2020 - Aug 2020
            </Typography>
            <List>
              <ListItem><ListItemText primary="🎨 Created a virtual gallery for VCU art graduates." /></ListItem>
              <ListItem><ListItemText primary="💻 Enabled real-time offline multi-user experience." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Project Cavoider</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Aug 2020 - Dec 2020
            </Typography>
            <List>
              <ListItem><ListItemText primary="🦠 Led a team to build a COVID-19 spread awareness app." /></ListItem>
              <ListItem><ListItemText primary="🌎 Integrated datasets for geographical analysis." /></ListItem>
            </List>
          </Box>

          <Divider />

          <Box mb={2}>
            <Typography variant="h5">Project Prehendo</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Jan 2020 - April 2020
            </Typography>
            <List>
              <ListItem><ListItemText primary="😱 Developed VR experience on anxiety and environment." /></ListItem>
              <ListItem><ListItemText primary="🏆 Accepted at Indie Bits Art Festival." /></ListItem>
            </List>
          </Box>
        </Box>

        {/* Education Section */}
        <Box paddingBottom={12}>
          <Typography variant="h4" gutterBottom>
            Academic Street Cred
          </Typography>
          <Typography variant="body1">
            <strong>Virginia Commonwealth University</strong> - B.S. in Computer Science, GPA: 3.96 (2019 - 2022)
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
